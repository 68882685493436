import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EncrdecrService } from 'src/app/Auth/encrdecr.service';
import { ClientsFilterService } from 'src/app/services/clients-filter.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
declare var $: any;
@Component({
  selector: 'app-clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.scss']
})
export class ClientsFilterComponent implements OnInit {
  @Output() FilterData: EventEmitter<any> = new EventEmitter();
  @Input() isMultiSelect: boolean = false;
  @Input() DDLFilterType: string = 'T';
  @Input() HierarchyClient = true;
  @Input() FilterType: string = "byClient"; // i have changed simple FilterType to @Input type
  clientFilterForm: FormGroup;
  LoginId: string;
  UserType: string = "";
  Param1: string = "";
  ClientData: any;
  TempClientData: any;
  FamilyData: any;
  EmployeeData: any;
  EmployeeDataH: any;
  EmployeeSetting = {};
  SelectedEmployee: any;
  SubbrokerData: any;
  HierarchyData: any;
  PlaceData: any;
  PlaceDataALL: any;
  ResponseData: any;
  selectedClient: any;
  IsNetsale : boolean = false;
  @Input() isShowClientFilter: boolean = true;
  isdisplayFamilyName: boolean = true;
  isDisplayClientName: boolean = true;

  @Input() isShowEmpSubBrokerFilter: boolean = true;

  isDisplayEmployeeName: boolean = true;
  isDisplaySubBrokerName: boolean = true;
  isDisplayEmpSubBrokerFamilyName: boolean = true;
  isDisplayEmpSubClientName: boolean = true;

  isShowHiearchyFilter: boolean = true;

  ClientDataSetting = {};
  resultDisplayName = [];
  AllEmpData = [];
  result = [];
  selectedItems: any[] = [];
  constructor(
    private fb: FormBuilder,
    private encryptDecrypt: EncrdecrService,
    private ClientFilterService: ClientsFilterService,
    private Loader: CustomLoaderService,
    private router: Router) {
    this.clientFilterForm = this.fb.group({
      ClientBasicInfoId: [''],
      FamilyId: [''],
      EmpId: [''],
      SubbrokerId: [''],
      HierarchyId: [''],
      PlaceId: ['']
    });
  }

  ngOnInit() {
    debugger
    let data = window.location.pathname;
    switch (data) {
      case '/reports/Monthly-SIP-collection-report':
        this.isShowClientFilter = false;
        this.isShowClientFilter = false;
        this.isShowHiearchyFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = true;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Redemption-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Client-FD-PortFolio-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/FD-Mobilization-And-Maturity-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Fresh-SIP-Addition':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/MF-Mobilization-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/reversal/Rejection-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Final-Mobilization-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/FD-Maturity-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/fd-bond-form-entry-report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
        case '/reports/net-sell-report':
          this.isDisplayEmployeeName = true;
          this.isDisplaySubBrokerName = false;
          this.isDisplayEmpSubClientName = false;
          this.isDisplayEmpSubBrokerFamilyName = false;
          this.isDisplayClientName = false;
          this.isdisplayFamilyName = false;
          this.IsNetsale = true;
 
          break;
      // commented code by Ravi Vaghela
      default:
        //this.isShowClientFilter = true;
        this.isDisplayClientName = true;
        this.isdisplayFamilyName = true;
        // this.isShowEmpSubBrokerFilter = true;
        this.isShowHiearchyFilter = true;


        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        break;
    }


    this.UserType = this.encryptDecrypt.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    if (this.UserType == '2') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }
    if (this.UserType == '3') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
    }
    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }

    console.log('----------------------' + this.UserType)
    //console.log(this.router.url);

    this.BindDropdown();
    //when user redirected from explore fund to transaction pages
    try {
      this.selectedClient = JSON.parse(this.encryptDecrypt.getDecr(sessionStorage.getItem('QQX4$E6Qnd')))
    } catch (err) {

    }

    if (this.selectedClient != undefined) {
      // this.clientFilterForm.controls['ClientBasicInfoId'].patchValue(`${this.selectedClient.ClientBasicInfoId}`);
      $(".ClientSelect").prop("disabled", true);
      sessionStorage.removeItem('QQX4$E6Qnd')
      this.FilterData.emit(this.selectedClient)
    }

    this.ClientDataSetting = {
      singleSelection: false,
      idField: 'ClientBasicInfoId',
      textField: 'clientname',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    //Added By Ravi Vaghela
    if (this.FilterType == 'byHierachy') {
      this.onCheck('byHierachy')
    }
  }
  ngAfterViewInit() {
    $('select').select2();
    $(".ClientSelect").on("change", (e: any) => this.onChangeClient(e));
    $(".FamilySelect").on("change", (e: any) => this.onChangeFamily(e));
    $(".EmployeeSelect").on("change", (e: any) => this.onChangeEmployee(e));
    $(".SubbrokerSelect").on("change", (e: any) => this.onChangeSubbroker(e));
    $(".HierarchySelect").on("change", (e: any) => this.onChangeHierarchy(e));
    $(".PlaceSelect").on("change", (e: any) => this.onChangePlace(e));
  }
  onChangeClient(event: any) {
    debugger
    let ClientBasicInfoId = event.target.value;
    this.clientFilterForm.controls['ClientBasicInfoId'].setValue(ClientBasicInfoId);
    let filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == ClientBasicInfoId);
    if (filterData.length > 0) {
      let SubBrokerName:any = '';
      if(this.SubbrokerData != undefined && this.SubbrokerData != null && this.SubbrokerData.length > 0){
        SubBrokerName = this.SubbrokerData.find(item => item.SubbrokerCode == filterData[0].SubBrokerCode);
      }
      filterData[0].ClientID = ClientBasicInfoId;
      filterData[0].flag = "Client";
      filterData[0].resultDisplayName = "";
      filterData[0].SubBrokerName = SubBrokerName.SubbrokerName;
      this.FilterData.emit(filterData[0]);
    }
  }
  onChangeFamily(event: any) {
    let Family = event.target.value;
    //filtering data by family 

    let filterData = this.TempClientData.filter(
      item => item.ClientGroupName == Family);
    this.ClientData = filterData;
    if (filterData.length == 1) {
      //this.clientFilterForm.controls['ClientBasicInfoId'].setValue(filterData[0].ClientBasicInfoId);

      this.ClientFilterService.setClientDropdownData(filterData[0]);
      $(".ClientSelect").prop("disabled", true);
      $('.ClientSelect').val(filterData[0].ClientBasicInfoId).trigger('change');
    }
  }
  onChangeEmployee(event: any) {
    let ID_Code = event.target.value;
    this.clientFilterForm.controls['EmpId'].setValue(ID_Code);
    this.LoginId = ID_Code;
    this.Param1 = 'Direct'
    this.BindDropdown();
    if (this.isDisplayEmployeeName == true) {
      let json = {
        "EmployeId": ID_Code,
        "flag": "employee"
      }
      this.FilterData.emit(json);
    } else {
      this.FilterData.emit(ID_Code);
    }
  }

  onChangeSubbroker(event: any) {
    debugger
    let SubbrokerId = event.target.value;
    let SubBrokerName = event.target.options[event.target.options.selectedIndex].text;
    this.clientFilterForm.controls['SubbrokerId'].setValue(SubbrokerId);

    let json = {
      "SubBrokerId": SubbrokerId,
      "SubBrokerName":SubBrokerName,
      "flag": "SubBroker"
    }
    this.FilterData.emit(json)
    this.ClientData = [];
    this.TempClientData = [];
    this.FamilyData = [];
    $(".ClientSelect").val('').trigger('change')
    this.Loader.show();
    let request = {
      "LoginId": SubbrokerId,
      "LoginType": "2",
      "FilterType": "T",
      "Param1": this.Param1,
      "Param2": ""
    }

    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }
    this.ClientFilterService.WrapperApiCall(request, "api/ClientFilters/getClientFilters").subscribe(
      (res: any) => {

        this.ResponseData = res;
        //console.log(res)
        this.Loader.hide();
        if (res.code == 200) {
          console.log(res);
          if (res.ArrayOfResponse[0].ClientDetails.length > 0) {
            this.ClientData = res.ArrayOfResponse[0].ClientDetails;
            this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
            let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
            this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];
          }

        }
      }, (err: any) => {
        this.Loader.hide();
        console.log(err);
      })

    //this.setDropDownData();
  }
  onChangeHierarchy(event: any) {
    let HierarchyId = event.target.value;
    this.clientFilterForm.controls['HierarchyId'].setValue(HierarchyId);
    let PlaceDataFilter = this.AllEmpData.filter(item => item.HierarchyName == HierarchyId);
    this.PlaceDataALL = [...new Map(PlaceDataFilter.map(item => [item['PlaceName'], item])).values()]



  }
  onChangePlace(event: any) {
    let PlaceId = event.target.value;
    this.clientFilterForm.controls['PlaceId'].setValue(PlaceId);
    let EmployeeDataall = this.AllEmpData.filter(item => item.PlaceName == PlaceId);

    this.EmployeeDataH = [...new Map(EmployeeDataall.map(item => [item['ID_Code'], item])).values()]

    //this.setDropDownData();
  }
  onCheck(value: any) {
    debugger
    let data = window.location.pathname;
    let filterData = {
      ShowTransaction: false
    }
    this.FilterData.emit(filterData)

    //this.setDropDownData();
    this.ClearDropDown();
    this.FilterType = value;

    if (data != '/reports/Monthly-SIP-collection-report' && data != '/reports/fd-bond-form-entry-report'
      && data != '/reports/Final-Mobilization-Report' && data != '/reports/reversal/Rejection-Report'
      && data != '/reports/MF-Mobilization-Report' && data != '/reports/Fresh-SIP-Addition'
      && data != '/reports/FD-Mobilization-And-Maturity-Report' && data != '/reports/Client-FD-PortFolio-Report'
      && data != '/reports/Redemption-Report' && data != '/reports/FD-Maturity-Report') {
      if (this.FilterType == "byEmpSubroker") {
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = true;
        this.isDisplayEmpSubClientName = true;
        this.isDisplayEmpSubBrokerFamilyName = true;
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
      } else if (this.FilterType == 'byClient') {
        this.isDisplayClientName = true;
        this.isdisplayFamilyName = true;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
      } else if (this.FilterType == 'byHierachy') {
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
      }
      else if (this.FilterType == "byNetSellEmpSubroker")
      {
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
      }
    }
    // if (data == '/transaction-new/lumpsum' || data == '/transaction-new/sip' 
    // || data == '/transaction-new/swp' || data == '/transaction-new/stp' 
    // || data == '/explore-funds-new' || data == '/clients-new' 
    // || data == '/SGB/SGBInvest' || data == '/SGB/SGBOrderBook' 
    // || data == '/fd/fixed_deposite' || data == '/fd/fd-profile'
    // || data == '/fd/fd-orderbook' || data == '/fd/bajajfd-orderbook'
    // || data == '/goal-based-investing/park-money' || data == '/goal-based-investing/create-wealth') {
    //   if (this.FilterType == "byEmpSubroker") {
    //     this.isDisplayEmployeeName = true;
    //     this.isDisplaySubBrokerName = true;
    //     this.isDisplayEmpSubClientName = true;
    //     this.isDisplayEmpSubBrokerFamilyName = true;
    //     this.isDisplayClientName = false;
    //     this.isdisplayFamilyName = false;
    //   } else if (this.FilterType == 'byClient') {
    //     this.isDisplayClientName = true;
    //     this.isdisplayFamilyName = true;
    //     this.isDisplayEmployeeName = false;
    //     this.isDisplaySubBrokerName = false;
    //     this.isDisplayEmpSubClientName = false;
    //     this.isDisplayEmpSubBrokerFamilyName = false;
    //   } else if (this.FilterType == 'byHierachy') {
    //     this.isDisplayClientName = false;
    //     this.isdisplayFamilyName = false;
    //     this.isDisplayEmployeeName = false;
    //     this.isDisplaySubBrokerName = false;
    //     this.isDisplayEmpSubClientName = false;
    //     this.isDisplayEmpSubBrokerFamilyName = false;
    //   }
    // }

  }
  ClearDropDown() {
    $(".ClientSelect").prop("disabled", false);
    this.ClientData = undefined;
    this.ClientData = this.TempClientData;
    $('select').val('').trigger('change.select2');
    this.clientFilterForm.patchValue({
      ClientBasicInfoId: '',
      FamilyId: '',
      EmpId: '',
      SubbrokerId: '',
      HierarchyId: '',
      PlaceId: ''
    }

    );
  }
  BindDropdown() {
    this.Loader.show();
    let request = {
      "LoginId": this.LoginId,
      "LoginType": this.UserType,
      "FilterType": this.DDLFilterType,
      "Param1": this.Param1,
      "Param2": ""
    }

    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    }
    this.ClientFilterService.WrapperApiCall(request, "/api/ClientFilters/getClientFilters").subscribe(
      (res: any) => {

        this.ResponseData = res;
        //console.log(res)
        this.Loader.hide();
        if (res.code == 200) {
          console.log(res);
          if (res.ArrayOfResponse[0].ClientDetails.length > 0) {
            this.ClientData = res.ArrayOfResponse[0].ClientDetails;
            this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
            let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
            this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];
          }
          if (res.ArrayOfResponse[0].SubbrokerDetails.length > 0) {
            this.SubbrokerData = res.ArrayOfResponse[0].SubbrokerDetails;
          }
          if (res.ArrayOfResponse[0].EmployeeDetails.length > 0) {

            let filterData = res.ArrayOfResponse[0].EmployeeDetails.filter(item => item.ID_Code !== null);
            this.AllEmpData = filterData;
            this.EmployeeData = [...new Map(filterData.map(item => [item['ID_Code'], item])).values()]

            this.PlaceData = [...new Map(filterData.map(item => [item['PlaceName'], item])).values()]
            this.PlaceDataALL = this.PlaceData;

            this.HierarchyData = [...new Map(filterData.map(item => [item['HierarchyName'], item])).values()]
          }
        }
      }, (err: any) => {
        this.Loader.hide();
        console.log(err);
      })

  }

  setDropDownData() {
    this.ClientFilterService.setSelectedDropdownData(this.clientFilterForm.value);
  }


  onItemSelect(e: any) {
    this.ClearClientDetails();
    const ClientId = e.ClientBasicInfoId;
    const clientName = e.clientname;

    if (ClientId) {
      const i = this.result.indexOf(ClientId);
      if (i === -1) {
        if (this.result.length > 0) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName.push(clientName);
        }
        this.result.push(ClientId);
      } else {
        this.result.splice(i, 1);
        this.resultDisplayName.splice(i, 1);
        if (this.result.length > 1) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName = [];
          this.resultDisplayName.push(clientName);
        }
      }
      this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());

      let filterData = [];
      if (this.result.length > 1) {
        filterData = this.TempClientData.filter((item) => this.result.includes(item.ClientBasicInfoId));
      } else {
        filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == this.result[0]);
      }

      if (this.result.length > 0) {
        filterData[0].ClientID = this.result;
        filterData[0].flag = "Client";
        filterData[0].resultDisplayName = this.resultDisplayName;
      } else {
        this.resultDisplayName = [];
        filterData = [{ "ClientID": "", "resultDisplayName": this.resultDisplayName }];
      }
      this.FilterData.emit(filterData[0]);
      console.log(filterData);

    }
  }

  onItemSelectDeselectAll(items: any, para: number) {
    this.ClearClientDetails();
    switch (para) {
      case 0:
        this.result = [];
        this.resultDisplayName = [];
        // this.selectedItems=[];
        this.resultDisplayName.push('All');
        items.map(item => {
          this.result.push(item.ClientBasicInfoId);
        });
        break;
      case 1:
        this.result = [];
        this.resultDisplayName = [];
        this.selectedItems = [];
        break;
    }

    let filterData = this.TempClientData;
    filterData[0].ClientID = this.result;
    filterData[0].flag = "Client";
    filterData[0].resultDisplayName = this.resultDisplayName;
    console.log(filterData);
    this.FilterData.emit(filterData[0]);
    this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());

  }

  ClearClientDetails() {
    this.clientFilterForm.controls.ClientBasicInfoId.setValue("");
  }


}
